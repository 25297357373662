<script lang="js" setup>
const props = defineProps({
  couriers: {
    type: Array,
    required: true,
  },
});

const shipping = useShippingStore();

const isSelected = (c) => {
  const selected = shipping.selectedCourier.some(
    (s) => s.id === c.id && s.merchant_id === shipping.requestedMid,
  );
  // console.log(
  //   `isSelected ${shipping.requestedMid} ${c.title}  ${selected}:`,
  //   c,
  // );
  return selected;
};
</script>
<template>
  <div id="courierList" class="flex-row w-full">
    <div v-for="(c, k) in props.couriers" :key="k" class="couriers">
      <Summary class="courierSummary hover:bg-slate-200">
        <template #leftIcon>
          <div class="leftIcon">
            <nuxt-img
              :src="c.courier_logo"
              :alt="c.courier_name"
              width="36"
              height="36"
            />
          </div>
        </template>
        <template #topSummary>
          <div
            v-if="c.price_discount === c.price"
            class="font-semibold courier_name"
          >
            <span>{{ c.title }}</span>
            (<span class="line-through text-gray-400 mx-1">{{
              formatRupiah(c.price)
            }}</span>
            <span>GRATIS</span>)
          </div>
          <div
            v-if="c.price > c.price_discount && c.price_discount > 0"
            class="font-semibold courier_name"
          >
            <span>{{ c.title }}</span>
            (<span class="line-through text-gray-400 mx-1">{{
              formatRupiah(c.price)
            }}</span>
            {{ formatRupiah(c.price_discount) }} )
          </div>
          <div v-if="c.price_discount === 0" class="font-semibold courier_name">
            {{ c.title }} ( {{ formatRupiah(c.price) }} )
          </div>
        </template>
        <template #bottomSummary>
          <div class="shipment_duration_range">
            Pengiriman (
            {{
              c.shipment_duration_range === "0"
                ? "di hari yang sama"
                : c.shipment_duration_range
            }}
            )
            <span v-if="c.shipment_duration_range !== '0'">
              {{
                c.shipment_duration_unit === "days" ||
                c.shipment_duration_unit === "day"
                  ? "hari"
                  : ""
              }}
              {{
                c.shipment_duration_unit === "hours" ||
                c.shipment_duration_unit === "hour"
                  ? "jam"
                  : ""
              }}
            </span>
          </div>
        </template>
        <template #rightIcon>
          <input
            class="flk-radio"
            type="radio"
            name="scourier"
            :value="c"
            :checked="isSelected(c)"
            @click="
              amplitudeTrackBtnClicked('Select Shipping Button', c.title, '');
              selectCourierMerchant(c, c.merchant_id);
            "
          />
        </template>
      </Summary>
    </div>
  </div>
</template>
